class Config {
  constructor () {
    this.graphBackend = 'https://www.ricehouse-graph.ntu.asia/'
    this.basicBackendBaseURL = 'https://www.ricehouse-backend.ntu.asia/'
    this.appBackendBaseURL = 'https://www.ricehouse-app.ntu.asia/'
    this.recordGetMaxAmountBasedOnBackendSet = 10
  }
}

export default new Config()

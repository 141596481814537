import axios from 'axios'
import Config from '@/config/config'
const API_URL = Config.basicBackendBaseURL

// 因為服務有好幾個關於auth因此以class方式來打包
class AuthService {
  login (user) {
    return axios.post(
      API_URL + 'service/signin', {
        username: user.username,
        password: user.password
      }
    ).then(res => {
      // // console.log('saving token0')
      if (res.data.result.accessToken) {
        // 這邊將收到的json data取出放在localstorage名叫user的 key中(他要將它字串化取出)
        // 到時候要取出token應該就得以accessToken配合user
        // // console.log('saving token1')
        localStorage.setItem('user', JSON.stringify(res.data.result))
      }
      // 最終都會回傳一個res.data出去(其實不用應該也OK ...但有這個可以看是否成功)
      return res.data
    })
  }

  // logout : 是唯一沒有使用到axios做async任務，他單純就是清除user於localstorage中
  logout () {
    localStorage.removeItem('user')
  }

  // 暫時沒有打算做會員系統，因為內部操作是給super user用的，僅需後台新增超級使用者帳密即可，頂多做超級使用者登入追蹤
  // register(user) {
  //   return axios.post(
  //     API_URL + 'signup',{
  //       username: user.username,
  //       email: user.email,
  //       password: user.password
  //     }
  //   ).then(res => {
  //     // 本來是沒有這個then，但我覺得有個回應能知道是否註冊成功
  //     return res.data
  //   })
  // }
}

export default new AuthService()
